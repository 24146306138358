import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() hideCloseButton: boolean = false;
  @Input() isConfirm: boolean = false;
  
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  public onBtnClicked(type: boolean) {
    this.activeModal.close(type);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
